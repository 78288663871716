import React from 'react';
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open('/link.php?link=wa');
  };
  const handleClick2 = () => {
    window.open('/link.php?link=tg');
  };
  
  return (
  <>
    <div className="whatsapp-icon" onClick={handleClick}>
      <svg id="Bold" enable-background="new 0 0 24 24" height="512" fill="#fff" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z"/><path d="m20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z"/></svg>
    </div>
        <div className="tg-icon" onClick={handleClick2} dangerouslySetInnerHTML={{__html: '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path style="fill:#EBF0FA;" d="M135.876,280.962L10.105,225.93c-14.174-6.197-13.215-26.621,1.481-31.456L489.845,36.811 	c12.512-4.121,24.705,7.049,21.691,19.881l-95.571,406.351c-2.854,12.14-17.442,17.091-27.09,9.19l-112.3-91.887L135.876,280.962z" 	/> <path style="fill:#BEC3D2;" d="M396.465,124.56L135.876,280.962l31.885,147.899c2.86,13.269,18.5,19.117,29.364,10.981 	l79.451-59.497l-65.372-53.499l193.495-191.693C410.372,129.532,403.314,120.449,396.465,124.56z"/> <path style="fill:#AFB4C8;" d="M178.275,441.894c5.858,2.648,13.037,2.302,18.85-2.052l79.451-59.497l-32.686-26.749l-32.686-26.749 	L178.275,441.894z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>'}} >
        </div>    
    </>    
  );
};

export default WhatsAppIcon;